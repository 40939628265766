import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Sidebar from "../components/sidebar";
import Stage from "../components/sections/stage";
import Services from "../components/sections/services";
import Work from "../components/sections/work";
import Clients from "../components/sections/clients";
import About from "../components/sections/about";
import Contact from "../components/sections/contact";

const StyledMainContainer = styled.div`
  scroll-snap-type: y mandatory;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  display: grid;

  @media (max-width: ${props => props.theme.break}) {
    .contentBlock {
      padding-top: 10vh;
      padding-bottom: 10vh;
      border-top: 1px solid ${props => props.theme.offwhite};
    }
  }

  @media (min-width: ${props => props.theme.break}) {
    grid-template-columns: 1fr 3fr;

    .stage,
    .contentBlock {
      scroll-snap-align: center;
      min-height: 100vh;
      box-sizing: border-box;
      display: grid;
      align-content: center;
      scroll-snap-align: center;
    }

    .stage {
      grid-column: span 2;
    }

    .sidebar {
      position: sticky;
      top: 0;
    }

    .contentBlock {
      grid-column: 2;
    }
  }
`;

const IndexPage = () => {
  const {
    allNavYaml: { nodes },
  } = useStaticQuery(graphql`
    query NavQuery {
      allNavYaml {
        nodes {
          title
          anchor
        }
      }
    }
  `);

  // stage ref and intersection observer stuff
  const [stageRef, stageInView] = useInView({
    threshold: 0.5,
  });

  // add intersection observer refs and stuff to
  // array containing all content sections
  const navItems = nodes.map(item => {
    const [ref, inView] = useInView({
      threshold: 0.5,
    });

    item.ref = ref;
    item.inView = inView;

    return item;
  });

  const getNavRef = anchor => navItems.find(item => item.anchor === anchor).ref;

  return (
    <Layout>
      <SEO />

      <StyledMainContainer>
        <Stage ref={stageRef} />

        <Sidebar navItems={navItems} stageInView={stageInView} />

        <Services getNavRef={getNavRef} />
        <Work ref={getNavRef("arbeitsproben")} />
        <Clients ref={getNavRef("kunden")} />
        <About ref={getNavRef("ueber-mich")} />
        <Contact ref={getNavRef("kontakt")} />
      </StyledMainContainer>
    </Layout>
  );
};

export default IndexPage;
