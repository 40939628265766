import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledAbout = styled.article`
  @media (min-width: ${props => props.theme.break}) {
    padding-right: 0;
    grid-template-columns: 1fr 0.7fr;
    grid-template-areas:
      "headline photo"
      "text     photo";
    align-items: start;
    grid-gap: 5vw;

    h2 {
      grid-area: headline;
      align-self: end;
      margin-top: 5vh;
      margin-bottom: 0;
    }

    .text {
      grid-area: text;
      font-size: 16px;
      line-height: 1.7;
    }

    .photo {
      grid-area: photo;
    }
  }

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1.4fr;
  }
`;

const About = React.forwardRef((props, ref) => {
  const {
    dataYaml: {
      about: { headline, image, content },
    },
  } = useStaticQuery(graphql`
    query AboutSectionQuery {
      dataYaml {
        about {
          headline
          image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
        }
      }
    }
  `);

  return (
    <StyledAbout ref={ref} id="ueber-mich" className="contentBlock padding">
      <h2 className="sectionHeadline">{headline}</h2>

      <Img fluid={image.childImageSharp.fluid} className="photo" />

      <div className="text">
        {content.split("\n").map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </StyledAbout>
  );
});

export default About;
