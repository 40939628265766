import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const WorkGrid = styled.section`
  display: grid;
  grid-gap: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

  @media (max-width: ${props => props.theme.break}) {
    align-items: center;
  }

  @media (min-width: ${props => props.theme.break}) {
    grid-template-columns: 1.15fr 1.1fr 1.2fr 1.7fr;
  }

  .thumbnail {
    box-shadow: 0 1vw 2vw rgba(0, 0, 0, 0.2);
  }

  figure {
    display: grid;
    grid-template-rows: auto 3em;

    a {
      align-self: center;
    }

    figcaption {
      font-size: 12px;
      margin-top: 1em;
      align-self: end;
      line-height: 1.3;
    }
  }
`;

const Work = React.forwardRef((props, ref) => {
  const {
    dataYaml: { work },
  } = useStaticQuery(graphql`
    query WorkSectionQuery {
      dataYaml {
        work {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          file
        }
      }
    }
  `);
  const works = work;

  return (
    <article ref={ref} id="arbeitsproben" className="contentBlock padding">
      <h2 className="sectionHeadline">
        Eis am Stil: Geschmacksproben zum Lesen
      </h2>

      <WorkGrid>
        {works.map((work, index) => (
          <figure key={index}>
            <a href={work.file} target="_blank" rel="noopener noreferrer">
              <Img
                fluid={work.image.childImageSharp.fluid}
                alt={work.title}
                className="thumbnail"
              />
            </a>
            <figcaption>{work.title}</figcaption>
          </figure>
        ))}
      </WorkGrid>
    </article>
  );
});

export default Work;
