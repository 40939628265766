import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import Logo from "../../icons/logo-big.svg";

const StyledStage = styled.header`
  display: grid;
  justify-items: center;
  padding: 5vw;
  min-height: 65vh;

  @media (max-width: ${props => props.theme.break}) {
    padding-bottom: 0;
  }

  @media (min-width: ${props => props.theme.break}) {
    grid-template-rows: 1fr 2fr 1fr;
    align-items: center;
    /* border: 1px solid blue; */
  }

  h1 {
    text-align: center;
    margin: 5vh 0;
    max-width: 1100px;
  }
`;

const Stage = React.forwardRef((props, ref) => {
  const {
    dataYaml: {
      stage: { headline },
    },
  } = useStaticQuery(graphql`
    query StageSectionQuery {
      dataYaml {
        stage {
          headline
        }
      }
    }
  `);

  return (
    <StyledStage ref={ref} className="stage">
      <Logo />
      <h1>{headline}</h1>
    </StyledStage>
  );
});

export default Stage;
