import React from "react";
import styled from "styled-components";

import Icon from "../icons/logo-icon.svg";

const StyledSidebar = styled.section`
  position: relative;
  display: grid;
  justify-items: center;

  &:after {
    content: "";
    width: 1px;
    height: 50px;
    background-color: ${props => props.theme.light};

    @media (min-width: ${props => props.theme.break}) {
      position: absolute;
      z-index: -1;
      top: 15vh;
      bottom: 0;
      height: auto;
    }
  }

  /* red squared icon */
  .logoIcon {
    @media (max-width: ${props => props.theme.break}) {
      display: none;
    }

    @media (min-width: ${props => props.theme.break}) {
      margin-top: 5vh;
      background-color: white;
    }
  }

  &.unstaged {
    @media (min-width: ${props => props.theme.break}) {
      &:after {
        transition: transform 300ms 150ms ease-in-out;
      }

      .logoIcon {
        transition: opacity 350ms 500ms ease-out;
      }
    }
  }

  nav {
    display: grid;

    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      display: grid;
      justify-items: center;
      padding: 1vw;

      @media (min-width: ${props => props.theme.break}) {
        align-self: center;
        background-color: white;
      }

      a {
        display: block;
        padding: 2px;
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 4px;
        line-height: 1.6;
        text-transform: uppercase;
        transition: color 150ms ease;

        &.inView {
          color: ${props => props.theme.dark};
          pointer-events: none;
        }
      }
    }
  }

  /* Moving the sidebar on scroll */
  @media (min-width: ${props => props.theme.break}) {
    grid-template-rows: auto 1fr auto;
    transition: transform 700ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

    &.staged {
      transform: translate(calc(50vw - 50%), -70vh);

      &:after {
        transform: scaleY(0.4);
      }

      .logoIcon {
        opacity: 0;
      }
    }
  }
`;

const Sidebar = React.forwardRef((props, ref) => (
  <StyledSidebar
    ref={ref}
    className={`sidebar ${props.stageInView ? "staged" : "unstaged"}`}
  >
    <a href="/">
      <Icon />
    </a>

    <nav>
      <ul>
        {props.navItems.map(item => (
          <li key={item.anchor}>
            <a className={item.inView ? "inView" : ""} href={`#${item.anchor}`}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  </StyledSidebar>
));

export default Sidebar;
