import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledClients = styled.article`
  @media (min-width: ${props => props.theme.break}) {
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 5vw;
  }
`;

const LogoGrid = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: grid;
  grid-gap: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  justify-items: center;
  align-items: center;

  @media (min-width: ${props => props.theme.break}) {
    grid-template-columns: repeat(4, 125px);
    justify-content: start;
    grid-gap: 3vw;
  }

  li {
    width: 100%;
    box-sizing: border-box;
  }

  .logo {
    filter: saturate(0);
    transition: filter 200ms ease;

    &:hover {
      filter: saturate(1);
    }
  }
`;

const Clients = React.forwardRef((props, ref) => {
  const {
    dataYaml: { clients },
  } = useStaticQuery(graphql`
    query ClientsSectionQuery {
      dataYaml {
        clients {
          title
          logo {
            childImageSharp {
              fixed(width: 125) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  return (
    <StyledClients ref={ref} id="kunden" className="contentBlock padding">
      <h2 className="sectionHeadline">Kunden</h2>

      <LogoGrid>
        {clients.map((client, index) => (
          <li key={index}>
            <Img
              fixed={client.logo.childImageSharp.fixed}
              alt={client.title}
              className="logo"
            />
          </li>
        ))}
      </LogoGrid>
    </StyledClients>
  );
});

export default Clients;
