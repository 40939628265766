import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import styled from "styled-components";

const StyledService = styled.article`
  p {
    max-width: 800px;
  }
`;

const Services = ({ getNavRef }) => {
  const {
    dataYaml: { services },
  } = useStaticQuery(graphql`
    query ServicesSectionQuery {
      dataYaml {
        services {
          anchor
          headline
          text
        }
      }
    }
  `);

  return (
    <>
      {services.map(service => (
        <StyledService
          ref={getNavRef(service.anchor)}
          className="contentBlock padding"
          key={service.anchor}
          id={service.anchor}
        >
          <h2 className="sectionHeadline">{service.headline}</h2>

          {service.text.split("\n").map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </StyledService>
      ))}
    </>
  );
};

export default Services;
