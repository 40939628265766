import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import styled from "styled-components";

const StyledContact = styled.article`
  background-image: url("/hirsch.svg");
  background-size: 40%;
  background-position: 98% 100%;
  background-repeat: no-repeat;

  @media (max-height: 700px) {
    font-size: 14px;
    line-height: 1.4;
  }

  @media (min-width: 500px) {
    background-size: auto 90%;
    background-position: 90% 100%;
  }

  address {
    font-style: normal;
    margin-top: 5vh;
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 5vh;
  }

  .credits {
    margin-top: 5vh;

    p {
      margin-bottom: 0.5em;
    }
  }
`;

const Contact = React.forwardRef((props, ref) => {
  const {
    dataYaml: {
      contact: { name, email, address, phone, credits },
    },
  } = useStaticQuery(graphql`
    query ContactSectionQuery {
      dataYaml {
        contact {
          name
          email
          address
          phone
          credits {
            text
            link
          }
        }
      }
    }
  `);

  return (
    <StyledContact ref={ref} id="kontakt" className="contentBlock padding">
      <h2 className="sectionHeadline">Kontakt</h2>

      <p>{name}</p>

      <p>
        <a href={`mailto:${email}`}>{email}</a>
      </p>

      <p>
        <a href={`tel:${phone}`}>{phone}</a>
      </p>

      <address>
        {address.split("\n").map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </address>

      <ul>
        <li>
          <Link to="/impressum">Impressum</Link>
        </li>
        <li>
          <Link to="/datenschutz">Datenschutz</Link>
        </li>
      </ul>

      <aside className="credits">
        {credits.map((credit, index) => (
          <p key={index}>
            {credit.text}
            <br />
            <a href={credit.link} target="_blank" rel="noopener noreferrer">
              {credit.link}
            </a>
          </p>
        ))}
      </aside>
    </StyledContact>
  );
});

export default Contact;
